import React from 'react';
import { VFC } from 'react';

export const Logo: VFC = () => (
  <svg width="149" height="28" viewBox="0 0 149 28" fill="none">
    <path
      d="M19.3309 3.47632V3.08466H12.2531V3.47632C13.9317 3.56024 14.7709 3.86797 14.7709 5.60243V11.785H4.55996V5.60243C4.55996 3.86797 5.39922 3.56024 7.07773 3.47632V3.08466H0V3.47632C1.67851 3.56024 2.54574 3.86797 2.54574 5.60243V19.2264C2.54574 21.0168 1.67851 21.2686 0 21.3805V21.7721H7.07773V21.3805C5.39922 21.2686 4.55996 21.0168 4.55996 19.2264V12.5683H14.7709V19.2264C14.7709 21.0168 13.9317 21.2686 12.2531 21.3805V21.7721H19.3309V21.3805C17.6524 21.2686 16.7851 21.0168 16.7851 19.2264V5.60243C16.7851 3.86797 17.6524 3.56024 19.3309 3.47632Z"
      fill="black"
    />
    <path
      d="M29.8067 7.81248V8.20413C31.0096 8.26008 31.5691 8.76363 31.2055 9.71479L27.3729 19.3383L23.5962 9.68682C23.2046 8.79161 23.82 8.26008 25.1069 8.20413V7.81248H19.7636V8.20413C20.6588 8.26008 21.1903 8.62376 21.6379 9.71479L26.4497 21.6602L25.9182 23.003C25.0789 25.1291 23.9319 26.0803 22.4772 26.0803C21.7499 26.0803 20.9106 25.7726 20.2672 25.325L19.5678 27.3392C20.1273 27.563 20.8546 27.7029 21.61 27.7029C23.876 27.7029 25.4146 26.1922 26.6455 23.1709L32.0447 9.71479C32.4923 8.62376 32.9679 8.26008 33.8911 8.20413V7.81248H29.8067Z"
      fill="black"
    />
    <path
      d="M43.5841 7.39285C41.5698 7.39285 39.7515 8.79161 38.9402 10.9177V7.39285H38.5485L34.9677 8.39996V8.79161H35.6951C36.7301 8.84756 37.1498 9.26719 37.1498 10.2463V25.1851C37.1498 26.4719 36.1427 26.7237 35.0516 26.8916V27.2832H41.7657V26.8916C39.9193 26.7237 38.9402 26.4719 38.9402 25.1851V20.2894C39.7515 21.4364 41.2062 22.1917 42.7168 22.1917C46.0459 22.1917 49.0952 19.5621 49.0952 14.3307C49.0952 10.4701 46.8851 7.39285 43.5841 7.39285ZM43.0525 21.3245C40.255 21.3245 38.9402 18.8627 38.9402 17.0443V11.8689C39.5836 10.0505 41.1222 8.81958 42.8287 8.81958C45.878 8.81958 47.1649 12.3724 47.1649 15.3938C47.1649 18.6109 45.4584 21.3245 43.0525 21.3245Z"
      fill="black"
    />
    <path
      d="M63.0218 18.0234C62.2105 19.2543 60.9516 20.4573 58.8535 20.4573C55.6364 20.4573 53.4543 17.9955 53.5662 14.1628C53.5662 13.939 53.5942 13.7432 53.5942 13.5194H63.3016C63.3016 9.96657 61.3713 7.39285 58.1541 7.39285C54.3215 7.39285 51.6918 10.5261 51.6918 15.086C51.6918 19.1424 54.0977 22.1638 57.8744 22.1638C60.532 22.1638 62.6302 20.4573 63.3295 18.1633L63.0218 18.0234ZM57.7625 8.17615C59.8606 8.17615 61.1754 10.3302 61.2314 12.7361H53.7061C54.1537 10.0505 55.6643 8.17615 57.7625 8.17615Z"
      fill="black"
    />
    <path
      d="M73.4826 7.39285C71.9439 7.39285 70.5452 8.65173 69.5381 11.1695V7.39285H69.1464L65.5656 8.39996V8.79161H66.2929C67.328 8.84756 67.7477 9.26719 67.7477 10.2463V19.674C67.7477 20.9608 66.7405 21.2126 65.6495 21.3805V21.7721H72.3636V21.3805C70.5172 21.2126 69.5381 20.9608 69.5381 19.674V11.9808C70.4333 10.1064 71.7761 9.15528 73.1469 9.15528C73.6225 9.15528 74.07 9.23921 74.4617 9.46301L74.6016 7.64462C74.2099 7.47677 73.8462 7.39285 73.4826 7.39285Z"
      fill="black"
    />
    <path
      d="M98.075 22.1917C102.327 22.1917 105.432 19.4502 106.16 15.1979L105.768 15.114C104.929 17.5199 102.775 19.0585 99.9773 19.0585C96.0887 19.0585 93.1513 15.9253 93.0954 11.1975C93.0674 6.55359 95.0257 3.44834 97.9351 3.44834C100.033 3.44834 101.544 5.35066 101.991 8.20413L105.852 7.05714C104.929 4.2876 101.964 2.66504 98.1869 2.66504C92.0883 2.66504 88.6193 7.11309 88.6753 12.848C88.7033 18.0514 92.5359 22.1917 98.075 22.1917Z"
      fill="black"
    />
    <path
      d="M111.572 5.79826C113.082 5.79826 114.201 4.70723 114.201 3.28049C114.201 1.88173 113.082 0.818672 111.572 0.818672C110.061 0.818672 108.942 1.88173 108.942 3.28049C108.942 4.70723 110.061 5.79826 111.572 5.79826ZM113.782 19.646V7.11309H113.39L107.683 8.70768V9.09934L108.103 9.15528C109.11 9.29516 109.641 9.82669 109.641 10.6939V19.646C109.641 20.765 109.25 21.1007 108.187 21.4084V21.7721H115.236V21.4084C114.173 21.1007 113.782 20.765 113.782 19.646Z"
      fill="black"
    />
    <path
      d="M126.213 19.7299C125.737 20.0376 125.262 20.4013 124.45 20.4013C123.052 20.4013 122.24 19.2264 122.24 17.8276V8.31603H126.297V7.53272H122.24V3.64417H121.849L116.561 7.92438V8.31603H118.1V17.9675C118.1 20.6251 119.695 22.1917 122.184 22.1917C124.59 22.1917 125.793 20.8769 126.437 19.9537L126.213 19.7299Z"
      fill="black"
    />
    <path
      d="M136.396 19.2264C133.319 19.2264 131.193 17.1842 131.333 13.4075V13.3236H140.593C140.565 9.57491 138.69 7.14107 134.55 7.14107C130.186 7.14107 127.304 10.2743 127.304 14.9741C127.304 18.7228 129.71 22.1638 134.382 22.1638C138.047 22.1638 140.089 19.8418 140.593 17.1562L140.257 17.0443C139.502 18.2752 138.131 19.2264 136.396 19.2264ZM134.102 7.92438C135.725 7.92438 136.424 10.61 136.48 12.5403H131.389C131.613 10.1064 132.424 7.92438 134.102 7.92438Z"
      fill="black"
    />
    <path
      d="M79.4893 12.7093C79.4893 9.32012 80.5021 6.34488 82.5278 3.78355C83.7744 2.21559 85.245 0.954398 86.9395 -1.52588e-05L87.2171 0.482061C85.6491 1.72864 84.5291 2.94114 83.8572 4.11954C82.6788 6.17445 82.0895 8.91109 82.0895 12.3294C82.0895 14.8616 82.3135 16.9506 82.7615 18.5964C83.5504 21.4792 85.0356 23.6363 87.2171 25.0679L86.8373 25.55C85.6394 24.9754 84.3441 23.8506 82.9514 22.1755C80.6433 19.3999 79.4893 16.2445 79.4893 12.7093Z"
      fill="black"
    />
    <path
      d="M148.952 12.8553C148.952 14.3064 148.762 15.6796 148.382 16.9749C148.003 18.2702 147.447 19.4924 146.717 20.6416C145.948 21.8687 144.92 23.013 143.635 24.0746C142.787 24.7758 142.076 25.2676 141.502 25.55L141.224 25.0679C142.724 23.9285 143.82 22.7647 144.511 21.5765C145.738 19.4729 146.352 16.6925 146.352 13.2352C146.352 10.4401 146.069 8.15632 145.504 6.38384C144.716 3.88094 143.289 1.91368 141.224 0.482061L141.604 -1.52588e-05C143.143 0.857009 144.574 2.15228 145.899 3.88581C147.934 6.55427 148.952 9.54412 148.952 12.8553Z"
      fill="black"
    />
    <path fillRule="evenodd" clipRule="evenodd" d="M137.175 27.6003H90.5496V26.8232H137.175V27.6003Z" fill="#001AFF" />
  </svg>
);
