import React, { FC } from 'react';
import styled from 'styled-components';

import { Footer } from './Footer';
import { Header } from './Header';

const LayoutContainer = styled.div`
  min-height: 100vh;
  min-width: 100vw;
  background-color: white;
`;

export const Layout: FC<{ isAuthenticated?: boolean }> = ({ children, isAuthenticated }) => {
  return (
    <LayoutContainer className="flex flex-col">
      <Header isAuthenticated={isAuthenticated} />
      <div style={{ minHeight: 'calc(100vh - 160px)' }}>{children}</div>
      <Footer />
    </LayoutContainer>
  );
};
