import { T, always, cond, has, prop, propEq } from 'ramda';
import styled from 'styled-components';

import { Colors } from '../utils/style-utils';

export interface TypographyProps {
  color?: string;
  bold?: boolean;
  underline?: boolean;
  upper?: boolean;
  lower?: boolean;
  link?: boolean;
  positive?: boolean;
}

const defaultProps = `
  font-family: 'Norse', sans-serif;
  font-style: normal;
  font-weight: normal;
`;

export const H1 = styled.h1`
  ${defaultProps}
  font-size: 42px;
  color: ${({ color }) => color || 'black'};
`;

export const H2 = styled.h2`
  ${defaultProps}
  font-size: 36px;
  color: ${({ color }) => color || 'black'};
`;

export const H3 = styled.h3`
  ${defaultProps}
  font-size: 32px;
  color: ${({ color }) => color || 'black'};
`;

export const H4 = styled.h4`
  ${defaultProps}
  font-size: 24px;
  color: ${({ color }) => color || 'black'};
`;

export const H5 = styled.h5`
  ${defaultProps}
  font-size: 20px;
  color: ${({ color }) => color || 'black'};
`;

// @ts-ignore
const getTextTransform: (a: TypographyProps) => string = cond([
  [propEq('upper', true), always('uppercase')],
  [propEq('lower', true), always('lowercase')],
  [T, always('none')],
]);

// @ts-ignore
const getTextColor: (a: TypographyProps) => string = cond([
  [propEq('link', true), always(Colors.Blue500)],
  [propEq('positive', true), always('#219653')],
  [has('color'), prop('color')],
  [T, always('black')],
]);

export const Typography = styled.p<TypographyProps>`
  font-family: 'Helvetica Neue', sans-serif;
  font-size: 20px;
  color: ${getTextColor};
  text-decoration: ${({ underline }) => (underline ? 'underline' : 'none')};
  text-transform: ${getTextTransform};
`;

export const Tag = styled.span<TypographyProps>`
  font-family: 'Helvetica Neue', sans-serif;
  font-size: 14px;
  color: ${getTextColor};
  text-decoration: ${({ underline }) => (underline ? 'underline' : 'none')};
  text-transform: ${getTextTransform};
`;

Typography.defaultProps = {
  bold: false,
  underline: false,
  positive: false,
};

Tag.defaultProps = {
  bold: false,
  underline: false,
  positive: false,
};
