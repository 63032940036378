import { ButtonHTMLAttributes } from 'react';
import styled from 'styled-components';

import { Colors } from '../utils/style-utils';

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children?: JSX.Element | string;
  fullWidth?: boolean;
  variant?: 'primary' | 'secondary' | 'tertiary';
  rounded?: boolean;
  icon?: boolean;
}

function getButtonBackground(props: ButtonProps) {
  if (props.disabled) {
    return '#E0E0E0';
  }

  switch (props.variant) {
    case 'secondary':
      return Colors.Blue900;
    case 'tertiary':
      return 'white';
    default:
      return Colors.Blue500;
  }
}

function getButtonTextColor(props: ButtonProps) {
  if (props.disabled) {
    return '#828282';
  }

  switch (props.variant) {
    case 'tertiary':
      return Colors.Blue900;
    default:
      return 'white';
  }
}

function getButtonBorderColor(props: ButtonProps) {
  if (props.disabled) {
    return '#E0E0E0';
  }

  switch (props.variant) {
    case 'secondary':
      return Colors.Blue900;
    case 'tertiary':
      return Colors.Purple200;
    default:
      return Colors.Blue500;
  }
}

export const Button = styled.button<ButtonProps>`
  display: flex;
  align-items: center;
  text-align: center;
  flex-direction: row;
  justify-content: center;
  padding: ${({ icon }) => (icon ? '10px' : '10px 20px')};

  position: relative;
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'auto')};
  height: 45px;

  background: ${getButtonBackground};
  border-radius: ${({ rounded }) => (rounded ? '500px' : '5px')};
  border-style: solid;
  border-width: 1px;
  border-color: ${getButtonBorderColor};

  color: ${getButtonTextColor};
  font-family: 'Norse', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  text-decoration: none;
  line-height: 19px;
`;

Button.defaultProps = {
  type: 'button',
  rounded: false,
  disabled: false,
  icon: false,
};
