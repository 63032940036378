import { navigate } from '@reach/router';
import { Menu, MenuButton, MenuItem, SubMenu } from '@szhsin/react-menu';
import Link from 'gatsby-link';
import { isNil } from 'ramda';
import Drawer from 'rc-drawer';
import React, { FC, useState } from 'react';
import { Menu as FiMenu, XCircle } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { Tooltip } from 'react-tippy';
import styled from 'styled-components';
import { useMediaLayout } from 'use-media';

import { logout as logoutAction } from '../lib/actions/me';
import { Paths } from '../paths';
import { RootState } from '../reducers';
import { Device, ViewportSize } from '../utils/media-queries-utils';
import { Colors } from '../utils/style-utils';
import { Anchor } from './Anchor';
import { Button } from './Button';
import { Logo } from './Logo';
import { Typography } from './Typography';

interface HeaderProps {
  isAuthenticated?: boolean;
  theme?: 'light' | 'dark';
}

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const typographyCommon = `
  font-family: "Norse", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  text-decoration: none;

  color: black;
  padding: 10px;
`;

const StyledLink = styled(Link)`
  ${typographyCommon}
`;

const StyledAnchor = styled(Anchor)`
  ${typographyCommon}
`;

const HeaderContainer = styled.div`
  height: 80px;
  padding: 0 105px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: white;

  ${Row} {
    flex: 2;
    justify-content: center;
  }

  ${Row}:first-child,
  ${Row}:last-child {
    flex: 1;
  }

  ${Row}:first-child {
    justify-content: flex-start;
  }

  ${Row}:last-child {
    justify-content: flex-end;
  }

  @media ${Device.laptop} {
    padding: 0 55px;
  }
`;

const Text = styled(Typography)`
  font-family: 'Norse';
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
`;

const SubHeader = styled(Typography)`
  font-family: 'Norse';
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  text-transform: uppercase;
  color: ${Colors.Purple300};
`;

export const Header: FC<HeaderProps> = () => {
  const isWide = useMediaLayout({ minWidth: ViewportSize.laptop });
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const me = useSelector(({ me }: RootState) => me.me);
  const dispatch = useDispatch();
  const isLoggedIn = !isNil(me);

  async function logout() {
    try {
      await dispatch(logoutAction());
      navigate(Paths.Home);
    } catch (err) {}
  }

  if (isWide) {
    return (
      <HeaderContainer>
        <Row>
          <Link to={Paths.Home}>
            <Logo />
          </Link>
        </Row>
        <Row>
          <StyledLink to={Paths.Home + '#how-it-works'}>How it works</StyledLink>
          <StyledLink to={Paths.Cost}>Cost</StyledLink>
          <StyledLink to={Paths.UserGuide}>User Guide</StyledLink>
          <Menu
            menuButton={
              <MenuButton>
                <Text>Sample Documents</Text>
              </MenuButton>
            }
          >
            <SubMenu label={<Text>Case #1 (state)</Text>}>
              <MenuItem>
                <StyledAnchor
                  style={{ padding: 0 }}
                  href="https://hypercite-public.s3-us-west-2.amazonaws.com/samples/AOB_sample.pdf"
                  download
                >
                  Sample AOB
                </StyledAnchor>
              </MenuItem>
              <MenuItem>
                <StyledLink style={{ padding: 0 }} to={Paths.Case1Guide}>
                  Case #1 Guide
                </StyledLink>
              </MenuItem>
            </SubMenu>
            <SubMenu label={<Text>Case #2 (state)</Text>}>
              <MenuItem>
                <StyledAnchor
                  style={{ padding: 0 }}
                  href="https://hypercite-public.s3-us-west-2.amazonaws.com/samples/CONFORMED_AOB-LL_OUTPUT.pdf"
                  download
                >
                  Sample AOB
                </StyledAnchor>
              </MenuItem>
              <MenuItem>
                <StyledAnchor
                  style={{ padding: 0 }}
                  href="https://hypercite-public.s3-us-west-2.amazonaws.com/samples/CONFORMED_RB-LL_OUTPUT.pdf"
                  download
                >
                  Sample RB
                </StyledAnchor>
              </MenuItem>
              <MenuItem>
                <StyledAnchor
                  style={{ padding: 0 }}
                  href="https://hypercite-public.s3-us-west-2.amazonaws.com/samples/CONFORMED_ARB-LL_OUTPUT.pdf"
                  download
                >
                  Sample ARB
                </StyledAnchor>
              </MenuItem>
              <MenuItem>
                <StyledLink style={{ padding: 0 }} to={Paths.Case2Guide}>
                  Case #2 Guide
                </StyledLink>
              </MenuItem>
            </SubMenu>
            <SubMenu label={<Text>Case #3 (fed)</Text>}>
              <MenuItem>
                <StyledAnchor
                  style={{ padding: 0 }}
                  href="https://hypercite-public.s3-us-west-2.amazonaws.com/samples/Lopez_9th_Cir.sample.pdf"
                  download
                >
                  Sample AOB
                </StyledAnchor>
              </MenuItem>
              <MenuItem>
                <StyledLink style={{ padding: 0 }} to={Paths.Case3Guide}>
                  Case #3 Guide
                </StyledLink>
              </MenuItem>
            </SubMenu>
          </Menu>

          <StyledLink to={Paths.Contact}>Contact Us</StyledLink>
        </Row>
        {isLoggedIn ? (
          <Row style={{ minWidth: 250 }}>
            <Text className="block mr-4">Case count: {me!.caseCount}</Text>
            <Button variant="primary" onClick={logout}>
              Log out
            </Button>
          </Row>
        ) : (
          <Row style={{ minWidth: 350 }}>
            <StyledLink to={Paths.SignIn} style={{ marginRight: 35 }}>
              Sign in
            </StyledLink>
            <Link className="no-underline" to={Paths.SignUp}>
              <Button variant="primary">Sign up</Button>
            </Link>
          </Row>
        )}
      </HeaderContainer>
    );
  }

  function onSidebarOpen() {
    setSidebarOpen(true);
  }

  function onSidebarClose() {
    setSidebarOpen(false);
  }

  return (
    <HeaderContainer>
      <Row>
        <Link to={Paths.Home}>
          <Logo />
        </Link>
      </Row>
      <Row style={{ minWidth: 215 }}>
        <button onClick={onSidebarOpen}>
          <FiMenu size={32} color="black" />
        </button>
        <Drawer
          handler={false}
          className="sidebar"
          width={300}
          open={sidebarOpen}
          placement="right"
          onClose={onSidebarClose}
          onHandleClick={onSidebarClose}
        >
          <div className="flex justify-end p-4">
            <button onClick={onSidebarClose}>
              <XCircle size={32} color="black" />
            </button>
          </div>
          <ul>
            <li className="py-2 mb-2 border-b border-solid border-grey-800">
              <StyledLink to={Paths.Home}>Home</StyledLink>
            </li>
            <li className="py-2 mb-2 border-b border-solid border-grey-800">
              <StyledLink to={Paths.Home + '#how-it-works'}>How it works</StyledLink>
            </li>
            <li className="py-2 mb-2 mt-2 border-b border-solid border-grey-800">
              <StyledLink to={Paths.Cost}>Cost</StyledLink>
            </li>
            <li className="py-2 mb-2 mt-2 border-b border-solid border-grey-800">
              <StyledLink to={Paths.UserGuide}>User Guide</StyledLink>
            </li>
            <li className="py-2 mb-2 mt-2 border-b border-solid border-grey-800">
              <Text style={{ padding: '0 10px' }}>Sample Documents</Text>
            </li>
            <li className="py-2 mb-2 mt-2 border-b border-solid border-grey-800">
              <SubHeader className="pl-4">Case #1 (state)</SubHeader>
            </li>
            <li className="py-2 mb-2 mt-2 border-b border-solid border-grey-800">
              <StyledAnchor
                style={{ padding: '0 1.5rem' }}
                href="https://hypercite-public.s3-us-west-2.amazonaws.com/samples/AOB_sample.pdf"
                download
              >
                Sample AOB
              </StyledAnchor>
            </li>
            <li className="py-2 mb-2 mt-2 border-b border-solid border-grey-800">
              <StyledLink style={{ padding: '0 1.5rem' }} to={Paths.Case1Guide}>
                Case #1 Guide
              </StyledLink>
            </li>

            <li className="py-2 mb-2 mt-2 border-b border-solid border-grey-800">
              <SubHeader className="pl-4">Case #2 (state)</SubHeader>
            </li>
            <li className="py-2 mb-2 mt-2 border-b border-solid border-grey-800">
              <StyledAnchor
                style={{ padding: '0 1.5rem' }}
                href="https://hypercite-public.s3-us-west-2.amazonaws.com/samples/CONFORMED_AOB-LL_OUTPUT.pdf"
                download
              >
                Sample AOB
              </StyledAnchor>
            </li>
            <li className="py-2 mb-2 mt-2 border-b border-solid border-grey-800">
              <StyledAnchor
                style={{ padding: '0 1.5rem' }}
                href="https://hypercite-public.s3-us-west-2.amazonaws.com/samples/CONFORMED_RB-LL_OUTPUT.pdf"
                download
              >
                Sample RB
              </StyledAnchor>
            </li>
            <li className="py-2 mb-2 mt-2 border-b border-solid border-grey-800">
              <StyledAnchor
                style={{ padding: '0 1.5rem' }}
                href="https://hypercite-public.s3-us-west-2.amazonaws.com/samples/CONFORMED_ARB-LL_OUTPUT.pdf"
                download
              >
                Sample ARB
              </StyledAnchor>
            </li>
            <li className="py-2 mb-2 mt-2 border-b border-solid border-grey-800">
              <StyledLink style={{ padding: '0 1.5rem' }} to={Paths.Case2Guide}>
                Case #2 Guide
              </StyledLink>
            </li>

            <li className="py-2 mb-2 mt-2 border-b border-solid border-grey-800">
              <SubHeader className="pl-4">Case #3 (fed)</SubHeader>
            </li>
            <li className="py-2 mb-2 mt-2 border-b border-solid border-grey-800">
              <StyledAnchor
                style={{ padding: '0 1.5rem' }}
                href="https://hypercite-public.s3-us-west-2.amazonaws.com/samples/Lopez_9th_Cir.sample.pdf"
                download
              >
                Sample AOB
              </StyledAnchor>
            </li>
            <li className="py-2 mb-2 mt-2 border-b border-solid border-grey-800">
              <StyledLink style={{ padding: '0 1.5rem' }} to={Paths.Case3Guide}>
                Case #3 Guide
              </StyledLink>
            </li>

            <li className="py-2 mb-2 mt-2 border-b border-solid border-grey-800">
              <StyledLink to={Paths.Contact}>Contact Us</StyledLink>
            </li>
            {!isLoggedIn && (
              <>
                <li className="py-2 mb-2 mt-2 border-b border-solid border-grey-800">
                  <StyledLink to={Paths.SignIn}>Sign in</StyledLink>
                </li>
                <li className="py-2 mb-2 mt-2">
                  <StyledLink to={Paths.SignUp}>Sign up</StyledLink>
                </li>
              </>
            )}
          </ul>
        </Drawer>
      </Row>
    </HeaderContainer>
  );
};

Header.defaultProps = {
  theme: 'light',
};
